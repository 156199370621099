import { createApp } from 'vue'
import '@sass/_mixins'
import '@sass/_transitions'
import '@sass/_global'
import '@sass/_typo'
import '@sass/_button'
import router from '@js/router'
// import apolloProvider from '@js/apollo_provider'
import cookieConsent from '@components/cookie_consent'
import mainFooter from '@components/main_footer'
import mainHeader from '@components/main_header'
import mainNav from '@components/main_nav'

/*
new Vue({
    el: '#app',
    router,
    apolloProvider,
    components:     {
        cookieConsent,
        mainFooter,
        mainHeader,
        mainNav
    }
})
*/

createApp({
  components: {
    cookieConsent,
    mainFooter,
    mainHeader,
    mainNav
  }
})
  .use(router)
  .mount('#app')
