<script setup>
import { onMounted } from 'vue'
import al from '@img/al'
import ar from '@img/ar'
import c1 from '@img/c1'
import c10 from '@img/c10'
import c11 from '@img/c11'
import c12 from '@img/c12'
import c13 from '@img/c13'
import c14 from '@img/c14'
import c15 from '@img/c15'
import c16 from '@img/c16'
import c17 from '@img/c17'
import c2 from '@img/c2'
import c3 from '@img/c3'
import c4 from '@img/c4'
import c5 from '@img/c5'
import c6 from '@img/c6'
import c7 from '@img/c7'
import c8 from '@img/c8'
import c9 from '@img/c9'
import back from '@img/mb2'
import { gsap } from 'gsap'

let isFlapping = false
const animateButterflyWheels = () => {
        gsap.to('.cw.ap', 3, {css:{rotation:360}, ease: 'none', repeat: -1});
        gsap.to('.cw.an', 3, {css:{rotation:-360}, ease: 'none', repeat: -1});
      },

      flapWings = () => {
        if (!isFlapping) {
          isFlapping = true

          const   t = (Math.floor((Math.random() * 2) + 2) / 10);
          let     tlwf = gsap.timeline({onComplete: () => {isFlapping = false}})

          tlwf
            .add([
              gsap.to('.w.l', t, {rotationY: 40, transformOrigin:'45% 50%'}),
              gsap.to('.w.r', t, {rotationY: -40, transformOrigin:'55% 50%'})
            ])
            .add([
              gsap.to('.w.l', t, {rotationY: 0, transformOrigin:'45% 50%'}),
              gsap.to('.w.r', t, {rotationY: 0, transformOrigin:'55% 50%'})
            ])
        }
      }

onMounted(animateButterflyWheels)
</script>

<template>
  <div class="butterfly-animation" @mouseover="flapWings">
    <img class="blueprint" :src="back" alt="">

    <div class="a">

      <img :src="al" class="ai l" alt="">
      <img :src="ar" class="ai r" alt="">

    </div>

    <div class="w l">

      <img class="cw c1 l ap" :src="c1" alt="">
      <img class="cw c2 l an" :src="c2" alt="">
      <img class="cw c3 l ap" :src="c3" alt="">
      <img class="cw c4 l an" :src="c4" alt="">
      <img class="cw c5 l an" :src="c5" alt="">
      <img class="cw c6 l ap" :src="c6" alt="">
      <img class="cw c7 l an" :src="c7" alt="">
      <img class="cw c8 l an" :src="c8" alt="">
      <img class="cw c9 l ap" :src="c9" alt="">
      <img class="cw c10 l an" :src="c10" alt="">
      <img class="cw c11 l an" :src="c11" alt="">
      <img class="cw c12 l ap" :src="c12" alt="">

    </div>

    <div class="w r">

      <img class="cw c1 r ap" :src="c1" alt="">
      <img class="cw c2 r an" :src="c2" alt="">
      <img class="cw c3 r ap" :src="c3" alt="">
      <img class="cw c4 r an" :src="c4" alt="">
      <img class="cw c5 r an" :src="c5" alt="">
      <img class="cw c6 r ap" :src="c6" alt="">
      <img class="cw c7 r an" :src="c7" alt="">
      <img class="cw c8 r an" :src="c8" alt="">
      <img class="cw c9 r ap" :src="c9" alt="">
      <img class="cw c10 r an" :src="c10" alt="">
      <img class="cw c11 r an" :src="c11" alt="">
      <img class="cw c12 r ap" :src="c12" alt="">

    </div>

    <img class="cw c13 c an" :src="c13" alt="">
    <img class="cw c14 c ap" :src="c14" alt="">
    <img class="cw c15 c an" :src="c15" alt="">
    <img class="cw c16 c ap" :src="c16" alt="">
    <img class="cw c17 c an" :src="c17" alt="">

  </div>
</template>

<style lang="scss">
.butterfly-animation {
  display: block;
  max-height: 342px;
  max-width: 548px;
  position: relative;
  vertical-align: top;

  .blueprint {
    height: auto;
    max-height: 342px;
    max-width: 548px;
    /*opacity: 0.5;*/
    visibility: hidden;
    width: 100%;
  }

  .a {
    left: 38.8%;
    position: absolute;
    top: -1.5%;
    width: 22.75%;
  }

  .ai {
    position: absolute;
    top: 0;
    width: 49%;

    &.l {
      left: 0;
    }

    &.r {
      right: 0;
    }
  }

  .w {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;

    &.l {
      left: 0;
    }

    &.r {
      right: 0;
    }
  }

  .cw {
    height: auto;
    position: absolute;
    width: 100%;
  }

  .c1 {
    top: 0;
    width: 14.5%;

    &.l {
      left: 0;
    }

    &.r {
      right: 0;
    }
  }

  .c2 {
    top: 8.5%;
    width: 11.6%;

    &.l {
      left: 13.6%;
    }

    &.r {
      right: 13.6%;
    }
  }

  .c3 {
    top: 19.8%;
    width: 13.3%;

    &.l {
      left: 22.4%;
    }

    &.r {
      right: 22.4%;
    }
  }

  .c4 {
    top: 33.6%;
    width: 12.3%;

    &.l {
      left: 32.3%;
    }

    &.r {
      right: 32.3%;
    }
  }


  .c5 {
    top: 25.6%;
    width: 14.6%;

    &.l {
      left: 8.7%;
    }

    &.r {
      right: 8.7%;
    }
  }

  .c6 {
    top: 40.6%;
    width: 14.4%;

    &.l {
      left: 19.6%;
    }

    &.r {
      right: 19.6%;
    }

  }

  .c7 {
    top: 52.6%;
    width: 11.6%;

    &.l {
      left: 32.8%;
    }

    &.r {
      right: 32.8%;
    }
  }

  .c8 {
    top: 56.4%;
    width: 6.4%;

    &.l {
      left: 15.1%;
    }

    &.r {
      right: 15.1%;
    }
  }

  .c9 {
    top: 61.7%;
    width: 9.3%;

    &.l {
      left: 25.1%;
    }

    &.r {
      right: 25.1%;
    }
  }

  .c10 {
    top: 70.4%;
    width: 6.4%;

    &.l {
      left: 34.7%;
    }

    &.r {
      right: 34.7%;
    }
  }

  .c11 {
    top: 65.4%;
    width: 11.9%;

    &.l {
      left: 14.5%;
    }

    &.r {
      right: 14.5%;
    }
  }

  .c12 {
    top: 75.5%;
    width: 14.4%;

    &.l {
      left: 23.5%;
    }

    &.r {
      right: 23.5%;
    }
  }

  .c13 {
    left: 47.2%;
    top: 32.5%;
    width: 5.8%;
  }

  .c14 {
    left: 42.8%;
    top: 41.3%;
    width: 14.6%;
  }

  .c15 {
    left: 46.2%;
    top: 64.1%;
    width: 7.8%;
  }

  .c16 {
    left: 47.5%;
    top: 76.2%;
    width: 5.2%;
  }

  .c17 {
    left: 48%;
    top: 84.1%;
    width: 4.2%;
  }
}
</style>
