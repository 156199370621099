<template>
  <footer class="main-footer">
    <div class="flex-wrapper">
      <address class="company-address">
        <p>
          The Mechanical Butterfly Ltd.<br>
          22 Montrose Street<br>
          Glasgow G1 1RE
        </p>
        <p>
          mail@mechanicalbutterfly.co.uk
        </p>
        <p>
          VAT Registration Number GB204492037
        </p>
        <p>
          Registered in Scotland with company number SC494308
        </p>
      </address>
      <ul class="link-list">
        <li>
          <router-link class="footer-link" :to="{name: 'start'}">start</router-link>
        </li>
        <li>
          <router-link class="footer-link" :to="{name: 'about'}">about</router-link>
        </li>
        <li>
          <router-link class="footer-link" :to="{name: 'services'}">services</router-link>
        </li>
        <li>
          <router-link class="footer-link"  :to="{name: 'contact'}">contact</router-link>
        </li>
      </ul>
    </div>
  </footer>
</template>

<style lang="scss">
@import '@sass/_mixins.scss';
@import '@sass/_variables.scss';

.main-footer {
  background-color: $dark;
  color: $white;
  display: block;
  padding: 3em 3em 3em 3em;

  .flex-wrapper {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;

    @media (max-width: $mobile-footer-max) {
      flex-direction: column;
    }
  }

  .link-list {
    @include unlist;

    @media (max-width: $mobile-footer-max) {
      border-top: 1px solid $white;
      padding-top: 2em;
      text-align: left;
      width: 100%;
    }

    @media (min-width: $mobile-footer-max + 1) {
      text-align: right;
    }
  }

  .footer-link {
    display: block;
    text-decoration: none;
    text-transform: uppercase;

    @media (max-width: $mobile-footer-max) {
      margin-bottom: 1.5em;
    }

    @media (min-width: $mobile-footer-max + 1) {
      margin-bottom: .5em;
    }
  }

  .company-address {
    font-style: normal;

    @media (max-width: $mobile-footer-max) {
      margin-bottom: 2em;
    }

    p {
      text-align: left;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {

        @media (max-width: $mobile-footer-max) {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
