<script setup>
import { onBeforeMount, ref } from 'vue'
import { useRouter } from 'vue-router'
import cookies from 'js-cookie'
import { trackingInit } from '@js/tracking'

const consentCookie = {
        'lifetime':     365,
        'name':         'cookie_consent',
        'value':        CONSENT_COOKIE_VALUE
      },
      displayBox = ref(true),
      displayDetails = ref(false),
      router = useRouter(),
      acceptCookies = () => {

            // see https://www.npmjs.com/package/js-cookie
            cookies.set(
              consentCookie.name,
              consentCookie.value,
              {
                expires: Number(consentCookie.lifetime)
              }
            )
            displayBox.value = false
            initialiseTracking()
        },
        cookieCheck = async () => {
          const cookie = cookies.get(consentCookie.name)
          if (typeof cookie != 'undefined' && cookie == consentCookie.value) {
            displayBox.value = false
            await router.isReady()
            initialiseTracking()
          }
        },
        initialiseTracking = () => {
          trackingInit()
          ga('send', 'pageview', router.currentRoute.path)
        }

onBeforeMount(cookieCheck)
</script>

<template>
  <div v-if="displayBox" class="cookie-consent">
    <div class="liner content-block">
      <p class="text-center">
          This website uses cookies.
      </p>
      <div v-if="displayDetails">
          <p>
              Path changes on this websites ('navigation') and UI interaction ('clicks') are tracked with Google Analytics. The website owner is using this data exclusively for further technical development and optimisation purposes.
          </p>
          <p>
              You can decline the use of cookies and still use the website normally.
          </p>
        <table>
          <thead>
            <tr>
              <th>Cookie Name</th>
              <th>Expiration Time</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>_ga</td>
              <td>2 years</td>
              <td>Used to distinguish users.</td>
            </tr>
            <tr>
              <td>_gid</td>
              <td>24 hours</td>
              <td>Used to distinguish users.</td>
            </tr>
            <tr>
              <td>_gat</td>
              <td>1 minute</td>
              <td>Used to throttle request rate.</td>
            </tr>
            <tr>
              <td>cookie_consent</td>
              <td>1 year</td>
              <td>Used to record your cookie consent for this website.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="text-center">
        <button class="button" type="button" @click="acceptCookies">Accept</button>
        <button class="button" type="button" @click="displayBox = false">Decline</button>
        <button class="button" type="button" @click="displayDetails = !displayDetails">
          <span v-if="!displayDetails">More</span><span v-else>Fewer</span> Details
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '@sass/_variables.scss';
@import '@sass/_mixins.scss';
@import '@sass/_button.scss';

.cookie-consent {
  background-color: $white-back;
  border-top: 1px solid $dark;
  bottom: 0;
  left: 0;
  max-height: 100%;
  overflow-y: auto;
  position: fixed;
  width: 100%;
  z-index: 200;

  table {
    border-collapse: collapse;
    margin: 2.5rem auto 3rem;

    @media (max-width: $mobile-max) {
      display: block;
    }

    thead {
      @media (max-width: $mobile-max) {
        display: none;
      }
    }

    th,
    td {
      padding: .5rem .25rem;
      text-align: left;
      vertical-align: top;

      @media (max-width: $mobile-max) {
        display: block;
      }
    }

    td {

      &::before {

        @media (max-width: $mobile-max) {
          font-weight: 600;
        }
      }

      &:nth-child(1) {

        &::before {

          @media (max-width: $mobile-max) {
            content: 'Cookie Name: ';
          }
        }
      }

      &:nth-child(2) {

        &::before {

          @media (max-width: $mobile-max) {
            content: 'Expiration Time: ';
          }
        }
      }

      &:nth-child(3) {

        &::before {

          @media (max-width: $mobile-max) {
            content: 'Description: ';
          }
        }
      }
    }

    tr {
      border-bottom: 1px solid $white;

      @media (max-width: $mobile-max) {
        border-bottom: 1px solid $dark;
        display: block;
      }
    }
  }

  .button {

    @media (max-width: $mobile-max) {
      display: block;
      margin-bottom: 1.5em;
      width: 100%;
    }

    @media (min-width: $mobile-max + 1) {
      margin: 0 4px;
    }
  }

  .liner {
    padding: 2rem;

    p {

      &:first-child {
        margin-bottom: 2em;
      }
    }
  }
}
</style>
