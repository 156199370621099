export default [

    // Start page.
    {
        component:  () => import('@components/route_start'),
        name:       'start',
        path:       '/'
    },

    // About page.
    {
        component:  () => import('@components/route_about'),
        name:       'about',
        path:       '/about'
    },

    // Services page.
    {
        component:  () => import('@components/route_services'),
        name:       'services',
        path:       '/services'
    },

    // Contact page.
    {
        component:  () => import('@components/route_contact'),
        name:       'contact',
        path:       '/contact'
    },

    // Payment success page.
    {
        component:  () => import('@components/route_payment_success'),
        name:       'payment-success',
        path:       '/payment/success/:sessionId?'
    },

    // Payment record failed page.
    {
        component:  () => import('@components/route_payment_record_failed'),
        name:       'payment-record-failed',
        path:       '/payment/record-failed'
    },

    // Payment page.
    {
        component:  () => import('@components/route_payment'),
        name:       'payment',
        path:       '/payment/:short?'
    },

    // No found page.
    // For everything that's not listed in this router.
    // (Mind you, this always needs to come last in routes array.)
    {
        component:  () => import('@components/route_404'),
        name:       'not-found',
        path:       '/:pathMatch(.*)*'
    }
]
